<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-container>
        <v-row>
          <v-col md="4">
            <v-text-field
              v-model="name"
              :label="$t('search by name')"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="mime_type"
              :label="$t('search by type')"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <!-- <v-col md="2.5">
            <v-text-field
              v-model="size_from"
              :label="$t('search by size from')"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col> -->
          <!-- <v-col  md="2.5">
            <v-text-field
              v-model="size_to"
              :label="$t('search by size to')"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col> -->
          <v-col md="4" class="d-flex justify-center align-center">
            <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        serverRoute="/attachment"
        @re-fetch-paginated-data="fetchData($event)"
        :enableDelete="displayDeleteIcon"
        :flaggedItems="flaggedItemsData"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <FileComponent :Id="row.id"></FileComponent>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import FileComponent from "./download.vue";
export default {
  components: {
    FileComponent,
  },
  data() {
    return {
      name: "",
      mime_type: null,
      username: "",
      joining_date_from: null,
      dialog: false,
      birthdayDialog: false,
      joining_date_Dialog: false,
      joining_date_from_Dialog: false,
      submitBtnLoading: false,
      loading: false,

      size_from: null,
      size_to: null,

      flaggedItemsData: ["size", "mimetype", "path"],
      newTeacher: {
        name: "",
        username: "",
        password: "",
        birth_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        joining_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        specialization: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "attachments/getTableOptions",
    }),
    disableSubmitBtn() {
      if (
        !this.newTeacher.name ||
        !this.newTeacher.birth_date ||
        !this.newTeacher.joining_date ||
        !this.newTeacher.specialization ||
        !this.newTeacher.username ||
        !this.newTeacher.password
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchAttachments: "attachments/fetchAttachments",
    }),

    fetchData(page) {
      this.dialog = false;
      this.fetchAttachments({
        params: {
          page,
          name: this.name,
          mime_type: this.mime_type,
          size_from: this.size_from,
          size_to: this.size_to,
        },
      });
    },
    async submitNewTeacher() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/teacher", this.newTeacher);
        this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style></style>
